function transformStringToJDM(input) {
  const modifiedString = input.replace(/\s+/g, "-") + "-JDM";
  return modifiedString;
}

function capitalizeFirstChar(str) {
  if (!str) return ""; // Handle empty string
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
export { transformStringToJDM, capitalizeFirstChar };
