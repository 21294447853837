import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { apiRequest, apiUrl, baseUrl } from "../../utils/api";
import { Link } from "react-router-dom";
import { getDate } from "../../utils/date";
import { capitalizeFirstChar } from "../../utils/string";
import Swal from "sweetalert2";

function Offers() {
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    document.title = `Offers | JDM Plates`;
    getOffers();
  }, []);

  async function getOffers() {
    try {
      let res = await apiRequest.get(`/offer/all`);
      if (res.data.status === 200) {
        let data = res.data.message;
        setOffers(data.filter((d) => d.bid_status != "deleted"));
      } else {
        setOffers([]);
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function createFullName(title, firstName, lastName) {
    let first = title == "mr" ? "Mr." : title == "miss" ? "Miss" : "Mrs.";
    return `${first} ${capitalizeFirstChar(firstName)} ${capitalizeFirstChar(
      lastName
    )}`;
  }

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => <>{index + 1}</>,
    },
    {
      name: "Reg Number",
      selector: (row) => (
        <>
          {row?.bidding_fullNumber ? (
            <button type="button" className="btn btn-warning yello-number">
              {row.bidding_fullNumber}
            </button>
          ) : (
            <p>No Plate</p>
          )}
        </>
      ),
    },
    {
      name: "Name",
      selector: (row) => (
        <>
          <p>
            {createFullName(
              row?.bidder_title,
              row?.bidder_first_name,
              row?.bidder_last_name
            )}
          </p>
        </>
      ),
    },

    {
      name: "Order Date",
      selector: (row) => (
        <>
          {" "}
          <p>{getDate(row.bidding_date)}</p>{" "}
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          {" "}
          <div className="edit-delet">
            {row.Action}
            <Link to={`/premiumlisting/${row?.bidding_fullNumber}`}>
              <img src="/img/eye.png" />{" "}
            </Link>
            <Link onClick={() => checkPermission(row.bidding_id)}>
              <img src="/img/trash.png" />{" "}
            </Link>
          </div>
        </>
      ),
    },
  ];

  async function checkPermission(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await apiRequest.put("/offer/delete-status", { bidId: id });
        Swal.fire({
          position: "top-end",
          icon: "info",
          title: "Please Wait For Few Moments",
          showConfirmButton: false,
          timer: 1500,
        });
        if (res.data.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Data Has Been Deleted",
            showConfirmButton: false,
            timer: 1500,
          });
          window?.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      }
    });
  }

  return (
    <div className="main-content">
      <div className="row">
        <div className="table-title selet">
          <h2>Offers</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="datatable0">
            <DataTable columns={columns} data={offers} pagination />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Offers;
