import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../utils/api";
import Swal from "sweetalert2";

function Notification() {
  const { n_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    Swal.fire({
      title: "Loading...",
      text: "Please wait",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    changeNotificationStatus();
  }, []);

  async function changeNotificationStatus() {
    try {
      let res = await apiRequest.put(`/notifications/status/read`, { n_id });
      if (res.data.status === 200) {
        navigate("/premiumlisting/" + res.data.message?.notification_number);
      } else {
        alert(res.data.message);
        navigate("/");
      }
    } catch (error) {
      alert(error.message);
      navigate("/");
    }
  }


  return <div></div>;
}

export default Notification;
