import React from "react";
import { getDate } from "../../../utils/date";
import DataTable from "react-data-table-component";

function Table({ plates }) {
  const colums = [
    {
      name: "S.No",
      selector: (row, index) => <>{index + 1}</>,
    },
    {
      name: "Number",
      selector: (row) => (
        <>
          {row?.sell_number ? (
            <button type="button" className="btn btn-warning yello-number">
              {row?.sell_number}
            </button>
          ) : (
            <p>No Plate</p>
          )}
        </>
      ),
    },
    {
      name: "Price",
      selector: (row) => <>£{row?.sell_price}</>,
    },

    {
      name: "Date",
      selector: (row) => (
        <>
          {" "}
          <p>{getDate(row?.notification_date)}</p>{" "}
        </>
      ),
    },
  ];
  return (
    <div>
      <div className="number-table table-responsive">
        <DataTable columns={colums} data={plates} pagination />
      </div>
    </div>
  );
}

export default Table;
